<template>
  <mobile-screen
    :header="true"
    screen-class="edit-form-screen add-company icon-app1"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="companies-header icon-hea1">
        <template v-slot:left>
          <router-link :to="{ name: 'r_manage-companies' }">
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          Add company
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <template v-if="listsLoaded">
      <VeeForm @submit="submitForm" v-slot="{ errors }" class="form" novalidate>
        <ul class="clebex-item-section">
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.name }"
          >
            <span class="error-message" v-if="errors.name">
              {{ errors.name }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="name" class="clebex-section-input-label">
                  Name
                </label>
                <Field
                  name="name"
                  as="input"
                  type="text"
                  rules="required"
                  id="name"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.subdomain }"
          >
            <span class="error-message" v-if="errors.subdomain">
              {{ errors.subdomain }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="subdomain" class="clebex-section-input-label">
                  Subdomain
                </label>
                <Field
                  name="subdomain"
                  as="input"
                  type="text"
                  rules="required"
                  id="subdomain"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="emailDomainId" class="clebex-section-input-label">
                  Email domain
                </label>
                <Field
                  name="email_domain"
                  as="input"
                  type="text"
                  id="emailDomainId"
                  :disabled="submitting"
                />
              </div>
            </div>
          </li>
          <li class="clebex-item-section-item full-right-underline">
            <router-link
              :to="{ name: 'r_add-company-select-company-type' }"
              class="clebex-item-section-item full-right-underline cursor-pointer"
              :class="{ 'has-error': errors.company_type_id }"
            >
              <span class="error-message" v-if="errors.company_type_id">
                {{ errors.company_type_id }}
              </span>
              <div class="clebex-item-content-wrapper">
                <div class="clebex-section-input">
                  <label
                    for="companyTypeId"
                    class="clebex-section-input-label cursor-pointer"
                  >
                    Company type
                  </label>
                  <p v-if="selectedCompanyType" class="text-label ellipsis">
                    {{ selectedCompanyType.description }}
                  </p>
                  <Field
                    name="company_type_id"
                    as="input"
                    type="hidden"
                    rules="required"
                    id="companyTypeId"
                    :disabled="submitting"
                    v-model="companyTypeId"
                  >
                  </Field>
                </div>
                <span class="follow-up-icons">
                  <span class="follow-up-icon-item">
                    <icon
                      icon="#cx-app1-arrow-right-12x12"
                      width="12"
                      height="12"
                    ></icon>
                  </span>
                </span>
              </div>
            </router-link>
          </li>
        </ul>

        <div class="clebex-item-section">
          <router-link
            :to="{ name: 'r_add-company-select-country' }"
            class="clebex-item-section-item full-right-underline"
            :class="{ 'has-error': errors.country_id }"
          >
            <span class="error-message" v-if="errors.country_id">
              {{ errors.country_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  for="countryId"
                  class="clebex-section-input-label cursor-pointer"
                >
                  Country
                </label>
                <p v-if="selectedCountry" class="text-label ellipsis">
                  {{ selectedCountry.name }}
                </p>
                <Field
                  name="country_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="countryId"
                  :disabled="submitting"
                  v-model="countryId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'r_add-company-select-language' }"
            class="clebex-item-section-item full-right-underline cursor-pointer"
            :class="{ 'has-error': errors.language_id }"
          >
            <span class="error-message" v-if="errors.language_id">
              {{ errors.language_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  for="languageId"
                  class="clebex-section-input-label cursor-pointer"
                >
                  Language
                </label>
                <p v-if="selectedLanguage" class="text-label ellipsis">
                  {{ displayLanguage(selectedLanguage.id) }}
                </p>
                <Field
                  name="language_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="languageId"
                  :disabled="submitting"
                  v-model="languageId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'r_add-company-select-timezone' }"
            class="clebex-item-section-item full-right-underline cursor-pointer"
            :class="{ 'has-error': errors.timezone_id }"
          >
            <span class="error-message" v-if="errors.timezone_id">
              {{ errors.timezone_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label for="timezoneId" class="clebex-section-input-label">
                  Timezone
                </label>
                <p v-if="selectedTimezone" class="text-label ellipsis">
                  {{ displayTimezone(selectedTimezone.id) }}
                </p>
                <Field
                  name="timezone_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="timezoneId"
                  :disabled="submitting"
                  v-model="timezoneId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
          <router-link
            :to="{ name: 'r_add-company-select-data-region' }"
            class="clebex-item-section-item full-right-underline cursor-pointer"
            :class="{ 'has-error': errors.data_region_id }"
          >
            <span class="error-message" v-if="errors.data_region_id">
              {{ errors.data_region_id }}
            </span>
            <div class="clebex-item-content-wrapper">
              <div class="clebex-section-input">
                <label
                  for="dataRegionId"
                  class="clebex-section-input-label cursor-pointer"
                >
                  Data region
                </label>
                <p v-if="selectedDataRegion" class="text-label ellipsis">
                  {{ displayDataRegion(selectedDataRegion.id) }}
                </p>
                <Field
                  name="data_region_id"
                  as="input"
                  type="hidden"
                  rules="required"
                  id="dataRegionId"
                  :disabled="submitting"
                  v-model="dataRegionId"
                >
                </Field>
              </div>
              <span class="follow-up-icons">
                <span class="follow-up-icon-item">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  ></icon>
                </span>
              </span>
            </div>
          </router-link>
        </div>
        <button type="submit" style="display: none;" ref="submitFormBtn">
          Submit form
        </button>
      </VeeForm>
      <info-modal v-if="show" :show="show" @close="show = false">
        <h3 class="modal-title">
          Company creation in progress, it might take up to 10 minutes
        </h3>
      </info-modal>
    </template>
  </mobile-screen>
  <router-view />
</template>

<script>
import { defineAsyncComponent } from "vue";
import httpServiceAuth from "@/services/http-service";
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { apiEndpoints } from "@/services/constants";
import { mapActions, mapState } from "vuex";
import { errorHandler } from "@/services/error-handler";
import languagesMixin from "@/services/mixins/languages/languages-mixin";
import timezonesMixin from "@/services/mixins/timezones/timezones-mixin";
import countriesMixin from "@/services/mixins/countries/countries-mixin";
import dataRegionsMixin from "@/services/mixins/data_region/data-region";

export default {
  name: "AddCompany",
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    InfoModal: defineAsyncComponent(() =>
      import("@/components/global/InfoModal")
    )
  },
  data() {
    return {
      submitting: false,
      show: false
    };
  },
  created() {
    this.setSelectedCountry(null);
    this.setSelectedLanguage(null);
    this.setCompanyType(null);
    this.setSelectedDataRegion(null);
    this.setSelectedTimezone(null);
  },
  computed: {
    ...mapState("companies", ["companyTypes", "selectedCompanyType"]),
    ...mapState("language", ["languages"]),
    ...mapState("country", ["countries", "selectedCountry"]),
    ...mapState("timezone", ["timezones", "selectedTimezone"]),
    listsLoaded() {
      return !!(
        this.companyTypes &&
        this.companyTypes.data &&
        this.companyTypes.data.length &&
        this.languages &&
        this.languages.data &&
        this.languages.data.length &&
        this.countries &&
        this.countries.data &&
        this.countries.data.length &&
        this.timezones &&
        this.timezones.data &&
        this.timezones.data.length &&
        this.dataRegions &&
        this.dataRegions.data &&
        this.dataRegions.data.length
      );
    },
    countryId() {
      if (this.selectedCountry) {
        return this.selectedCountry.id;
      }
      return null;
    },
    languageId() {
      if (this.selectedLanguage) {
        return this.selectedLanguage.id;
      }
      return null;
    },
    timezoneId() {
      if (this.selectedTimezone) {
        return this.selectedTimezone.id;
      }
      return null;
    },
    dataRegionId() {
      if (this.selectedDataRegion) {
        return this.selectedDataRegion.id;
      }
      return null;
    },
    companyTypeId() {
      if (this.selectedCompanyType) {
        return this.selectedCompanyType.id;
      }
      return null;
    }
  },
  methods: {
    ...mapActions("companies", ["getCompanies", "setCompanyType"]),
    ...mapActions("country", ["setSelectedCountry"]),
    ...mapActions("timezone", ["setSelectedTimezone", "getTimezones"]),
    ...mapActions("language", ["setSelectedLanguage", "getLanguages"]),
    ...mapActions("dataRegion", ["setSelectedDataRegion"]),
    submitForm(values, { resetForm }) {
      if (values) {
        this.submitting = true;
        this.submitMasterData(values, resetForm);
      }
    },
    submitMasterData(values, resetForm) {
      values.is_active = 1;
      values.is_demo = 0;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      this.show = true;
      httpServiceAuth
        .post(apiEndpoints.master.companies, values)
        .then(() => {
          resetForm();
          this.getCompanies();
          this.setSelectedCountry(null);
          this.setSelectedLanguage(null);
          this.setSelectedDataRegion(null);
          this.setSelectedTimezone(null);
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.submitting = false;
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  mixins: [countriesMixin, languagesMixin, timezonesMixin, dataRegionsMixin]
};
</script>
